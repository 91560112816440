import React from 'react';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <header className={styles.header}>

      <div className={styles.headerLogo}>
        <img src="https://i.imgur.com/HO1oMlQ.png" alt="OPS" />
        <span>The One Piece TCG Marketplace™</span>
      </div>

      <div className={styles.headerActions}>
        <button><span>Login</span></button>
      </div>

    </header>
  );
};

export default Header;
