import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import styles from './MainSection.module.scss';

const MainSection = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   emailjs.send('service_id', 'template_id', { email }, 'user_id')
  //     .then(() => setSubmitted(true))
  //     .catch(console.error);
  // };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const templateParams = {
      from_email: 'tahir@endlessprojects.io',
      to_email: 'tahir@endlessprojects.io',
      subject: 'OPS Membership Request',
      message: email,
    };
  
    emailjs.send('service_88uyh12', 'template_pnxsbde', templateParams, 'jL22bBl8RDTtzqqwc')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
      }, (err) => {
        console.log('FAILED...', err);
      });
  };
  

  if (submitted) {
    return <p>You're on the list!</p>;
  }

  return (
    <main className={styles.mainSection}>
      <h1>Buy and sell<br/> One Piece TCG</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address"
        />
        <button type="submit">Request access</button>
      </form>
    </main>
  );
};

export default MainSection;
