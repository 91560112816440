import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>All original content herein is Copyright © 2024 OPS Gaming LLC. No portion of this website may be used without expressed written consent. All rights reserved. One Piece and its respective properties are copyright ©Eiichiro Oda/Shueisha, Toei Animation.</p>
    </footer>
  );
};

export default Footer;
