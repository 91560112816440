import React from 'react';
import Header from './shared/components/Header';
import MainSection from './shared/components/MainSection';
import Footer from './shared/components/Footer';

const App = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <Footer />
    </div>
  );
};

export default App;
